import React from 'react';
import './index.scss';
// 图片
import mobileModel from '../../static/images/mobile/mobile_model.png';
import logo from '../../static/images/mobile/logo.png';
import android from '../../static/images/mobile/android.png';
import apple from '../../static/images/mobile/apple.png';

function Mobile() {
  //#region 集成 openinstall
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//web.cdn.openinstall.io/openinstall.js';
  script.addEventListener('load', () => {
    // @ts-ignore
    const data = OpenInstall.parseUrlParams();
    // @ts-ignore
    new OpenInstall({
      //appkey参数配置,需要自行替换对应的appkey
      appKey: "xx1gfg",
      onready: function () {
        const that = this;
        const button = document.getElementById("android");
        that.schemeWakeup();
        if (button) {
          button.onclick = function () {
            that.wakeupOrInstall();
            return false;
          }
        }
      }
    }, data);
  }, false);
  document.head.appendChild(script);
  //#endregion

  return (
    <div className='mobile'>
      <div className='title'>上红料宝 看体育资讯</div>
      <div className='tips'>大神分析, 智能大数据</div>
      <img className='mobile-model' src={mobileModel} />
      <img className='logo' src={logo} />
      <div className='btn-box'>
        <button>
          <div id='android' className='btn-content'>
            <img src={android} />
            <span>安卓下载</span>
          </div>
        </button>

        <button>
          <div className='btn-content'>
            <img src={apple} />
            <span>iOS下载</span>
          </div>
        </button>
      </div>
      {/* 备案 */}
      <a className='internet-content-provider' href='https://beian.miit.gov.cn/#/Integrated/index'>
        <img src="" />
        <span>湘ICP备2021001009号-1</span>
      </a>
    </div>
  );
}

export default Mobile;
